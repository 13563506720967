import React from 'react';
import PrivacyPolicyPage from 'ui/content/privacy-policy';
import Layout from '../components/layout';

const PrivacyPolicy = () => (
  <Layout showFooter={false}>
    <PrivacyPolicyPage />
  </Layout>
);

export default PrivacyPolicy;
